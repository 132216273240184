<template>
  <b-container fluid="lg">
      
      <b-row class="justify-content-md-center border-bottom">
          <h1 class="m-5">Login</h1>
      </b-row>
      <b-row class="justify-content-md-center my-2">
          <b-spinner v-if="!show" class="m-5"></b-spinner>
            <b-form @submit="onSubmit" @reset="onReset" v-if="show">
                <b-form-group
                    id="input-group-1"
                    label="Usuario:"
                    label-for="input-1"
                >
                    <b-form-input
                    id="input-1"
                    v-model="form.user"
                    type="text"
                    required
                    placeholder="Enter email"
                    ></b-form-input>
                </b-form-group>

                <b-form-group id="input-group-2" label="Contraseña:" label-for="input-2">
                    <b-form-input
                    id="input-2"
                    v-model="form.password"
                    required
                    type="password"
                    placeholder="Password"
                    ></b-form-input>
                </b-form-group>

                <b-button class="mx-2" type="submit" variant="primary">Ingresar</b-button>
                <b-button class="mx-2" type="reset" variant="danger">Borrar</b-button>
            </b-form>
    </b-row>
  </b-container>
</template>

<script>
  export default {
    data() {
      return {
        form: {
          user: '',
          password: '',
        },
        show: true
      }
    },
    methods: {
      onSubmit(evt) {
        evt.preventDefault()
        this.login(this.form.user, this.form.password);
      },
      async login(user, password) {
        const thisV = this;
        thisV.show = false;
        const data = {username: user, password: password}
        try {
            const response = await thisV.$axios({
            method: "POST",
            url: `menu_app/authenticate/`,
            data: data
            });
            const payload = {
                token: response.data.token,
                id_client: response.data.id_client,
                id_usuario: response.data.id_user,
                usuario: self.usuario,
                is_active: response.data.is_active,
            };
            thisV.$store.dispatch('login', payload)
            thisV.$router.push('/')
            thisV.show = false;
        } catch (error) {
            alert("Usuario y/o contraseña inválido.")
            thisV.show = true;
        }
      },
      onReset(evt) {
        evt.preventDefault()
        // Reset our form values
        this.form.user = ''
        this.form.password = ''
        // Trick to reset/clear native browser form validation state
        this.show = false
        this.$nextTick(() => {
          this.show = true
        })
      }
    }
  }
</script>